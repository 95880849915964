import React from "react";

import { Base } from "../components/Base";
import { Container } from "../components/Container";
import SEO from "../components/seo";

class Page extends React.Component {
  render() {
    const css = {
      subTitle: "text-2xl mt-16 mb-8",
      sectionTitle: "text-xl mt-8 mb-4",
    };

    const Paragraph = ({ children }) => <p className="my-4">{children}</p>;

    return (
      <Base>
        <SEO title="Conditions Générales d'Utilisation" />

        <div className="pb-32">
          <div className="py-16 bg-gray-100">
            <Container>
              <h1 className="text-3xl font-bold">Mentions légales</h1>
            </Container>
          </div>
          <Container>
            <div className="max-w-2xl">
              <h2 className={css.subTitle}>Editeur du site</h2>
              <Paragraph>
                Les sites www.omnidoc.fr et www.app.omnidoc.fr sont édités par
                la société OmniDoc (ci-après "l’Editeur").
                <br />
                <br />
                <div className="italic">
                  Société par actions simplifiée au capital de 1 000 euros.
                  <br />
                  Siège social : 12 rue Anselme, 93400 Saint-Ouen-sur-Seine
                  <br />
                  RCS PARIS : 847 506 144.
                  <br />
                  Mail : support@omnidoc.fr
                  <br />
                  <br />
                  Directeur de la publication : Monsieur Baptiste Truchot, Président
                  <br />
                  Numéro de téléphone : 07 52 57 48 09
                </div>
              </Paragraph>
              <h2 className={css.subTitle}>Hébergement des données de santé</h2>
              <Paragraph>
                Les données de santé à caractère personnel collectées et
                traitées dans le cadre de l’utilisation de la plateforme Omnidoc
                sont hébergées auprès d’un hébergeur agréé de santé,
                conformément aux dispositions de l’article L.1111-8 du Code de
                la santé publique.
                <br />
                <br />
                En particulier, les données de santé et les serveurs sont
                hébergés par : OVH (ci-après "l’Hébergeur").
                <br />
                <br />
                <div className="italic">
                  Société par actions simplifiée au capital de 10.069.020 euros
                  <br />
                  Siège social : 2 rue Kellermann, 59100 Roubaix
                  <br />
                  RCS Lille 424 761 419
                </div>
                <br />
                <br />
                Les serveurs sont administrés par Claranet, infogéreur certifié
                pour l'hébergement et l'infogérance des données de santé
                (ci-après "l’Infogéreur").
                <br />
                <br />
                <div className="italic">
                  Siège social : 2 rue Bréguet 75011 Paris
                  <br />
                  RCS de Paris B 419 632 286
                </div>
              </Paragraph>
            </div>
          </Container>
        </div>
      </Base>
    );
  }
}

export default Page;
