/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// TODO: improve, see https://www.gatsbyjs.org/docs/add-seo-component/

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

function SEO({ title, description, image, isArticle, lang }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleTemplate
            description
            siteUrl
            author
            twitterUsername
          }
        }
      }
    `,
  );

  const meta = site.siteMetadata;

  const { pathname } = useLocation();

  const seo = {
    title: title || meta.title,
    description: description || meta.description,
    image: `${meta.siteUrl}${image || meta.image}`,
    url: `${meta.siteUrl}${pathname}`,
  };

  return (
    <Helmet titleTemplate={meta.titleTemplate}>
      <html lang={lang} />
      <title> {seo.title} </title>
      <meta name="description" content={seo.description} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={site.siteMetadata.twitterUsername}
      />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      {isArticle && <meta property="og:type" content="article" />}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
